

























import { Component } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '../../../../../support'
import { ModalSizeMap } from '../../../../../dsl/molecules/Modal'

import { Modals } from '../../../config'

import { DHLParcelData } from '../../DHLModal'

import { BaseDriver } from './BaseDriver.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<DHLPopDriver>({
  name: 'DHLPopDriver'
})
export class DHLPopDriver extends BaseDriver {
  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  protected parcel: DHLParcelData | null = null

  public get selectedParcel (): string {
    if (!this.parcel) {
      return this.cart.selectedShippingMethod?.displayInfo || ''
    }

    return `${this.parcel.name}, ${this.parcel.street} ${this.parcel.streetNo}, ${this.parcel.zip} ${this.parcel.city}`
  }

  public change (): void {
    this.modalConnector.open(
      Modals.DHLModal,
      {
        cart: this.cart,
        onSelection: this.onSelection,
        selected: this.selectedParcel.split(',')[0] || '',
        onCancellation: this.onCancellation
      },
      { size: ModalSizeMap.XLarge }
    )
  }

  protected onCancellation (): void {
    this.resolveCandidate(false)
    this.modalConnector.close(Modals.DHLModal)
  }

  protected async onSelection (parcel: DHLParcelData): Promise<void> {
    this.parcel = parcel

    const parcelId = `${parcel.sap}`

    this._callbacks = [
      {
        callable: this.checkoutService.setDHLPopParcel,
        onThis: this.checkoutService,
        args: [this.cart.id, parcelId]
      },
      {
        callable: this.checkoutService.setShippingDetails,
        onThis: this.checkoutService,
        args: [
          this.cart.id,
          {
            deliveryTime: this.deliveryTime,
            disabledPaymentMethods: this.disabledPaymentMethods,
            displayInfo: this.selectedParcel
          }
        ]
      }
    ]

    this.resolveCandidate(true)
    this.modalConnector.close(Modals.DHLModal)
  }

  protected selectCandidate (): void {
    this.modalConnector.open(
      Modals.DHLModal,
      {
        cart: this.cart,
        onClose: () => this.resolveCandidate(false),
        onCancellation: this.onCancellation,
        onSelection: this.onSelection
      },
      { size: ModalSizeMap.XLarge }
    )
  }
}

export default DHLPopDriver
