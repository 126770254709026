


















import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { DeliveryTime } from '../../../../../contexts'
import { StructureConfigurable } from '../../../../../support/mixins'

import { CartModel, CheckoutStepCallback } from '../../../contracts'
import { ICheckoutService } from '../../../services/checkout'

import { DriverShippingMethod } from './drivers.contracts'
import { defaultConfig, getDriverCallbacks, SHIPPINGS_COMPONENT_KEY } from './drivers.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<BaseDriver>({
  name: 'BaseDriver',
  created (): void {
    this.config = this.getComponentConfig(SHIPPINGS_COMPONENT_KEY, defaultConfig)
  }
})
export class BaseDriver extends Mixins(StructureConfigurable) {
  @PropSync('callbacks', { type: Array, required: true })
  protected _callbacks!: CheckoutStepCallback[]

  @Prop({ type: Object, required: true })
  protected readonly cart!: CartModel

  @Prop({ type: Object, required: true })
  protected readonly checkoutService!: ICheckoutService

  @Prop({ type: Boolean, required: true })
  protected readonly isCandidate!: boolean

  @Prop({ type: Object, required: true })
  public readonly method!: DriverShippingMethod

  @Prop({ type: Function, required: true })
  protected readonly resolveCandidate!: (result: boolean) => void

  @Prop({ type: String, required: false })
  protected readonly selected!: string | null

  protected deliveryTime: DeliveryTime = {
    deliveryFrom: '2',
    deliveryTo: '3'
  }

  protected disabledPaymentMethods: string[] = []

  /**
   * Determines the decorator for template.
   */
  public get templateDecorator (): string {
    const withPlusSign: boolean = this.getConfigProperty<AnyObject>('template').withPlus

    return withPlusSign ? '+' : '/'
  }

  public get isSelected (): boolean {
    return this.selected === this.method.carrierCode ||
      this.cart.selectedShippingMethod?.carrierCode === this.method.carrierCode
  }

  protected cancelCandidate (): void {
    // Do nothing
  }

  protected selectCandidate (): void {
    if (!this.$configuration) {
      throw new Error('Configuration is undefined!')
    }

    const callbacks = getDriverCallbacks(this.$configuration)

    this._callbacks = callbacks.map((cb: keyof ICheckoutService) => {
      return {
        callable: this.checkoutService[cb],
        onThis: this.checkoutService,
        args: [
          this.cart.id,
          {
            deliveryTime: this.deliveryTime,
            disabledPaymentMethods: this.disabledPaymentMethods
          }
        ]
      }
    })

    this.resolveCandidate(true)
  }

  @Watch('selected')
  private onSelectedChange (value: string): void {
    if (value !== this.method.carrierCode) this.resolveCandidate(false)
  }

  @Watch('isCandidate')
  private onCandidateChange (value: boolean): void {
    value ? this.selectCandidate() : this.cancelCandidate()
  }
}

export default BaseDriver
