






















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { ShippingCarrierCode, ShippingMethod, ShippingMethodCode } from '../../../../contexts'
import { Inject } from '../../../../support'

import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'
import { CartModel, CheckoutStepCallback } from '../../contracts'

import { defaultDriver, driversRegistry } from './drivers/drivers'
import {
  isFreeShipmentThresholdReached,
  shippingIcon,
  supportedShippingMethods
} from './Shippings.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Shippings' })
export class Shippings extends Vue {
  @PropSync('errors', { type: Array, required: false, default: () => ([]) })
  public _errors!: string[]

  @PropSync('selected', { type: Object, required: false, default: null })
  public _selected!: ShippingMethod | null

  @PropSync('callbacks', { type: Array, required: true })
  public _callbacks!: CheckoutStepCallback[]

  @Prop({ type: Object, required: true })
  protected readonly cart!: CartModel

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  public get methods () {
    const considerFreeShipment: boolean =
      isFreeShipmentThresholdReached(this.cart.availableShippingMethods.map((method) => method.methodCode))
    return this.cart?.availableShippingMethods
      .filter(({ carrierCode }) => supportedShippingMethods.includes(carrierCode))
      .filter((method) => {
        return considerFreeShipment ? method.price.value === 0 : true
      })
      .map(({ carrierCode, price }: ShippingMethod) => ({
        data: {
          carrierCode: carrierCode,
          description: this.$t(`front.checkout.organisms.ShippingStep.method.${carrierCode}.description`),
          image: shippingIcon[carrierCode],
          price,
          title: this.$t(`front.checkout.organisms.ShippingStep.method.${carrierCode}.title`)
        },
        driver: carrierCode in driversRegistry ? driversRegistry[carrierCode] : defaultDriver,
        id: carrierCode
      }))
  }

  public get selectedShippingCode (): ShippingCarrierCode | null {
    return this._selected?.carrierCode ?? null
  }

  public set selectedShippingCode (value: ShippingMethodCode | null) {
    this._selected =
      this.cart?.availableShippingMethods.find(method => method.carrierCode === value) ?? null
    this._errors = []
  }
}
export default Shippings
